import "@babel/polyfill";
import { createChatRoomClient } from 'chat-room-client';
import { AVRoom } from "chat-room-client/lib/av-room";
import { ChatRoomClient } from 'chat-room-client/lib/chat-room-client';
import { Participant } from 'chat-room-client/lib/participant';
import { ChatRoomType, IRoomCallbacks, Room } from "chat-room-client/lib/room";
import { Topic } from 'chat-room-client/lib/topic';
import { Janus } from 'janus-client';
import * as Polyglot from 'node-polyglot';
import * as adapter from 'webrtc-adapter/out/adapter.js';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

declare var CHAT_ROOM_SERVER: string;
declare var UAA_BASE_URL: string;
declare var IS_SECURE_CONNECTION: boolean;
declare var TURNSEVER: RTCIceServer;

const LCW_ROOTCLASS = 'livechatwidget';
const LCW = 'livechatwidget-';
const MAX_SLOW_LINK_EVENT = 3;

const secret = Janus.randomString(10);

const chatRoomClient: ChatRoomClient = createChatRoomClient({
    debug: true,
    domains: [window.location.host],
    isSecureConnection: true,
    server: CHAT_ROOM_SERVER,
    uaaBaseUrl: UAA_BASE_URL,
    iceServers: [TURNSEVER]
});
let rootElement: HTMLElement;
let type: ChatRoomType = ChatRoomType.Video;
let publisherId: number;
let topicList: Topic[];
let currentRoomId: number;
let polyglot: Polyglot;
let currentRoom: Room;
let converstionStarted = false;
let localStream: MediaStream;
let currentBitrateValue: number = 512;
let slowLinkEventCounter = 0;
const FULL_SCREEN_PROJECTS_ID = [503551, 302951];

export function initialize(root: HTMLElement, polyglotJs: Polyglot) {

    polyglot = polyglotJs;
    rootElement = root;
    // hack for working WebRTC adapter.js
    const globalObj = (root.ownerDocument!.defaultView as any);
    globalObj.adapter = adapter;
    // hack for fetch polyfill
    if (!globalObj.fetch) {
        globalObj.fetch = fetchPolyfill;
    }

    if(isFullScreen()){
        for (const exitButtonKey of root.getElementsByClassName(LCW + 'header__button')) {
            (exitButtonKey as HTMLButtonElement).remove();
        }
        for (const welcomeDescKey of root.getElementsByClassName(LCW + 'header')) {
            (welcomeDescKey as HTMLElement).classList.add('bottom');
        }
        for (const logoKey of root.getElementsByClassName(LCW + 'header__logo--sign')) {
            (logoKey as HTMLElement).remove();
        }
        for (const nicknameKey of root.getElementsByClassName(LCW + 'welcome__input--nickname')) {
            (nicknameKey as HTMLInputElement).remove();
        }
        for (const nicknameKey of root.getElementsByClassName(LCW + 'welcome__label--nickname')) {
            (nicknameKey as HTMLLabelElement).remove();
        }
        for (const regulationsKey of root.getElementsByClassName(LCW + 'welcome__agreements__policy-label')) {
            (regulationsKey as HTMLElement).classList.add('nobefore');
        }
        for (const videoButtonKey of root.getElementsByClassName(LCW + 'video')) {
            (videoButtonKey as HTMLElement).remove();
        }
        for (const elKey of root.getElementsByClassName(LCW + 'custom-remove')) {
            (elKey as HTMLElement).remove();
        }

    }else {
        for (const logoKey of root.getElementsByClassName(LCW + 'header__logo--custom')) {
            (logoKey as HTMLButtonElement).remove();
        }
        for (const descKey of root.getElementsByClassName(LCW + 'welcome__desc')) {
            (descKey as HTMLElement).remove();
        }
        for (const elKey of root.getElementsByClassName(LCW + 'custom-element')) {
            (elKey as HTMLElement).remove();
        }
    }
    
    for (const button of root.getElementsByClassName(LCW + 'welcome__type-button')) {
        (button as HTMLButtonElement).onclick = onButtonClick;
    }

    const topicSelect: HTMLSelectElement = getElementByClass('welcome__input--topic');
    topicSelect.disabled = false;

    const closeAgreements: HTMLSelectElement = getElementByClass('welcome__agreements__header--close')
    const returnAgreements: HTMLSelectElement = getElementByClass('welcome__agreements__button-back')
    if(isFullScreen()) {
        closeAgreements.onclick = toggleAgreementsDetails;
        returnAgreements.onclick = toggleAgreementsDetails;
        const acceptAgreements: HTMLSelectElement = getElementByClass('welcome__agreements__button-accept')
        acceptAgreements.onclick = acceptAgreementsDetails;
    }

    const chatForm: HTMLFormElement = getElementByClass('chat-new-message');
    chatForm.onsubmit = (ev: Event): any => {
        ev.preventDefault();
        sendMessage();
        return false;
    };

    const toggleUploadBtn: HTMLButtonElement = getElementByClass('chat-new-message__button--upload');
    toggleUploadBtn.onclick = toggleUpload;

    const videoBtn: HTMLElement = getElementByClass('videos__button--video');
    videoBtn.onclick = toggleVideo;
    const videoOffBtn: HTMLElement = getElementByClass('videos__button--video-off');
    videoOffBtn.onclick = toggleVideo;
    const audioBtn: HTMLElement = getElementByClass('videos__button--audio');
    audioBtn.onclick = toggleAudio;
    const audioOffBtn: HTMLElement = getElementByClass('videos__button--audio-off');
    audioOffBtn.onclick = toggleAudio;
    const screenShareBtn: HTMLButtonElement = getElementByClass('videos__button--screenshare');
    screenShareBtn.onclick = screenShare;
    const stioScreenSharingButton: HTMLButtonElement = getElementByClass('videos__button--stop-screensharing');
    stioScreenSharingButton.onclick = screenShare;

    const endCallButton: HTMLButtonElement = getElementByClass('videos__button--end-call');
    endCallButton.onclick = () => close();

    const stretchButton: HTMLButtonElement = getElementByClass('videos__button--stretch');
    stretchButton.onclick = toggleStretch;

    const fullscreenButton: HTMLButtonElement = getElementByClass('videos__button--fullscreen');
    fullscreenButton.onclick = toggleFullScreen;
    const fullscreenExitButton: HTMLButtonElement = getElementByClass('videos__button--fullscreen-exit');
    fullscreenExitButton.onclick = toggleFullScreen;

    const toggleButton: HTMLButtonElement = getElementByClass('toggle__button');
    toggleButton.onclick = toggle;

    if(isFullScreen()){
        // const closeButton: HTMLButtonElement = getElementByClass('header__button--close');
        // closeButton.onclick = () => close();
    }

    const textChatButton: HTMLButtonElement = getElementByClass('chat-info__button--message');
    textChatButton.onclick = toggleTextChat;
    
    const inputChatButton: HTMLInputElement = getElementByClass('chat-new-message__input--text');
    inputChatButton.onclick = inputChatButtonClick;

    const bitrateSelectt: HTMLSelectElement = getElementByClass('videos__button--bitrate');
    bitrateSelectt.onchange = () => changeBitrate();

    // tslint:disable:no-string-literal
    // tslint:disable:no-angle-bracket-type-assertion
    chatRoomClient.getAvailableTopicsForProject((<any>window)['SIGNCONNECT_PROJECT']).then(createTopicOptions).catch((error) => {
        alert(polyglot.t('get_available_topics_failed'));
    });
    if (!isWebrtcSupported()) {
        getElementByClass('container').classList.add(LCW + '-no-webrtc-support');
    }
}

function isStarted(): boolean {
    return !getElementByClass('container').classList.contains(LCW + 'container--welcome');
}

function close(confirmMessage?: string) {
    const widgetContainer: HTMLElement = getElementByClass('container');
    widgetContainer.classList.remove('livechatwidget-chat-videos');
    converstionStarted = false;
    if (isStarted() && currentRoomId) {
        const answer: boolean = confirm(polyglot.t(confirmMessage ? confirmMessage : 'close_confirm'));
        if (answer) {
            destroyRoom();
        }
    } else {
        toggle();
    }
}

function toggleStretch() {
    const widgetContainer: HTMLElement = getElementByClass('container');
    widgetContainer.classList.toggle('livechatwidget-chat-videos');
}

function toggleAgreementsDetails() {
    const agreementsDetails: HTMLElement = getElementByClass('welcome__agreements');
    agreementsDetails.classList.toggle('open')
}

function acceptAgreementsDetails() {
    toggleAgreementsDetails();
}

function destroyRoom() {
    chatRoomClient.destroyRoom(currentRoomId, secret)
                .then(
                    () => { destroy();},
                    error => { destroy();},
                )
                .catch(e => { destroy();});
}

function destroy() {
    if(localStream) {
        localStream.getTracks().forEach(track => {track.stop();});
    }
    reset();
    toggle();
}

function toggle() {
    if (rootElement.classList.contains(LCW_ROOTCLASS + '--fullscreenview')){
        rootElement.classList.remove(LCW_ROOTCLASS + '--minimized');
        getElementByClass('container').classList.add(LCW + 'container--welcome');
        getElementByClass('chat-history').innerHTML = '';
    }else {
        if (rootElement.classList.contains(LCW_ROOTCLASS + '--maximized') || rootElement.classList.contains(LCW_ROOTCLASS + '--minimized')) {
            rootElement.classList.remove(LCW_ROOTCLASS + '--maximized');
            rootElement.classList.remove(LCW_ROOTCLASS + '--minimized');
            getElementByClass('container').classList.add(LCW + 'container--welcome');
            getElementByClass('chat-history').innerHTML = '';
            document.querySelectorAll('.visibilityHidden').forEach((node) => node.classList.remove('visibilityHidden'));
        } else {
            rootElement.classList.add(LCW_ROOTCLASS + '--maximized');
            document.getElementsByTagName('body')[0]
                .childNodes
                .forEach(
                    node => {
                        // @ts-ignore
                        if ((node.nodeName !== 'SCRIPT') || !node.className.includes('livechatwidget')) {
                            // @ts-ignore
                            node.className += ' visibilityHidden';
                        }
                    }
                )
        }
    }
}

function toggleTextChat() {
    if (rootElement.classList.contains(LCW_ROOTCLASS + '--chat-text')) {
        rootElement.classList.remove(LCW_ROOTCLASS + '--chat-text');
    } else {
        rootElement.classList.add(LCW_ROOTCLASS + '--chat-text');
    }
}

function inputChatButtonClick() {
    const textChatButton: HTMLButtonElement = getElementByClass('chat-info__button--message');
    textChatButton.style.backgroundColor = 'transparent';
}

function toggleUpload() {
    getElementByClass('chat-new-message').classList.toggle(LCW + 'chat-new-message--file-upload')
}

function toggleFullScreen() {
    rootElement.classList.toggle(LCW_ROOTCLASS + '--maximized');
    rootElement.classList.toggle(LCW_ROOTCLASS + '--minimized');
}

function toggleAudio() {
    const enableAudio = !getElementByClass('videos').classList.toggle(LCW + 'videos--audio-disabled');
    const isVideoEnabled = !getElementByClass('videos').classList.contains(
        LCW + 'videos--video-disabled',
    );
    (currentRoom as AVRoom).configure(enableAudio, isVideoEnabled);
}

function toggleVideo() {
    const enableVideo = !getElementByClass('videos').classList.toggle(LCW + 'videos--video-disabled');
    const isAudioEnabled = !getElementByClass('videos').classList.contains(
        LCW + 'videos--audio-disabled',
    );

    // tslint:disable-next-line:no-console
    console.log('toggleVideo: isAudioEnabled, enableVideo', isAudioEnabled, enableVideo);

    if(enableVideo) {
        restartVideo(isAudioEnabled, enableVideo);
    } else {
        (currentRoom as AVRoom).configure(isAudioEnabled, enableVideo);
    }
}

function restartVideo(isAudioEnabled: boolean, enableVideo: boolean) {
    // tslint:disable-next-line:no-console
    console.log('restartVideo', isAudioEnabled, isAudioEnabled, currentBitrateValue);
    (currentRoom as AVRoom).configureOwnFeed(!isAudioEnabled, !enableVideo, currentBitrateValue*1000);
}

function changeBitrate(setDefault?: boolean) {
    const enableVideo = !getElementByClass('videos').classList.contains(LCW + 'videos--video-disabled');
    const isAudioEnabled = !getElementByClass('videos').classList.contains(LCW + 'videos--audio-disabled');
    const bitrateSelect: HTMLSelectElement = getElementByClass('videos__button--bitrate');

    let value: number;
    if(setDefault) {
        value = 512;
        bitrateSelect.value = value.toString();
    }else {
        value = +bitrateSelect.value;
    }

    currentBitrateValue = value;
    (currentRoom as AVRoom).configure(isAudioEnabled, enableVideo, currentBitrateValue*1000);
}

function decreaseBitrate() {
    const enableVideo = !getElementByClass('videos').classList.contains(LCW + 'videos--video-disabled');
    const isAudioEnabled = !getElementByClass('videos').classList.contains(LCW + 'videos--audio-disabled');
    const bitrateSelect: HTMLSelectElement = getElementByClass('videos__button--bitrate');

    const selectedIndex = bitrateSelect.options.selectedIndex;
    if(selectedIndex > 1) {
        (currentRoom as AVRoom).configure(isAudioEnabled, enableVideo, +bitrateSelect.options[selectedIndex-1].value*1000);
        --bitrateSelect.options.selectedIndex;
        restartVideo(isAudioEnabled, enableVideo);
    } else {
        // tslint:disable-next-line:no-console
        console.warn('can not decrease bitrate');
    }
}

function screenShare() {
    if (getElementByClass('videos').classList.contains(LCW + 'videos--screensharing-active')) {
        endScreenSharing();
        return;
    }
    (currentRoom as AVRoom).shareScreen().then(() => {
        getElementByClass('videos').classList.add(LCW + 'videos--screensharing-active');
    }).catch((error: Error) => {
        let msg = error.message;
        if (error.message === 'no_extension') {
            msg = polyglot.t('screensharing_no_extension');
        }
        alert(msg);
    });
}

function endScreenSharing() {
    (currentRoom as AVRoom).endScreenSharing();
    const enableVideo = !getElementByClass('videos').classList.contains(LCW + 'videos--video-disabled');
    const isAudioEnabled = !getElementByClass('videos').classList.contains(LCW + 'videos--audio-disabled');
    (currentRoom as AVRoom).configure(isAudioEnabled, enableVideo, currentBitrateValue*1000);
    getElementByClass('videos').classList.remove(LCW + 'videos--screensharing-active');
}

function sendMessage() {
    const fileInput: HTMLInputElement = getElementByClass('chat-new-message__input--file');
    if (fileInput.files && fileInput.files!.length > 0) {
        const file = fileInput.files![0];
        currentRoom.upload(file)
        .then(
            succes => {
                // tslint:disable-next-line:no-console
                console.log('upload sukcess');
            },
            error => {
                // TODO read max file size from server
                alert(polyglot.t('file_upload_error'));
            }
        );
        if (window.getComputedStyle(fileInput).display !== 'none') {
            toggleUpload();
        }
        fileInput.value = '';
        fileInput.files = null;
        return;
    }
    const chatInput: HTMLInputElement = getElementByClass('chat-new-message__input--text');
    if (!chatInput.value) {
        alert(polyglot.t('chat_message_placeholder'));
        return;
    }
    currentRoom.sendMessage(chatInput.value);
    chatInput.value = '';
}

function onButtonClick(ev: Event) {
    type = (ev.currentTarget as HTMLButtonElement).value as ChatRoomType;
    if (!isWebrtcSupported() && (type === ChatRoomType.Video || type === ChatRoomType.Audio)) {
        alert(polyglot.t('no_webrtc_support'));
    } else {
        start();
    }
}

function scrollChatHistory(chatHistory: HTMLElement) {
    if ('scroll' in chatHistory && 'scrollBehavior' in document.documentElement.style) {
        chatHistory.scroll({
            behavior: 'smooth',
            top: chatHistory.scrollHeight,
        });
    } else {
        chatHistory.scrollTop = chatHistory.scrollHeight;
    }
}

function start() {
    let nickname = '';
    const nicknameInput: HTMLInputElement = getElementByClass('welcome__input--nickname');
    if(isFullScreen()){
        nickname = 'użytkownik-mops';

    }else{
        nickname = nicknameInput.value || 'anonymous';
    }
    const topicInput: HTMLSelectElement = getElementByClass('welcome__input--topic');
    if(isFullScreen()) {
        getElementByClass('chat-waiting-info__number-in-queue').textContent = polyglot.t('chat_videos_waiting_info-custom');
    }else {
        getElementByClass('chat-waiting-info__number-in-queue').textContent = polyglot.t('chat_videos_waiting_info');
    }
    const container = getElementByClass('container');
    container.classList.remove(LCW + 'container--welcome');
    // FIXME parseInt !!!
    const topic = topicList.filter(t => parseInt(t.getId(), 10) === parseInt(topicInput.value, 10))[0];
    getElementByClass('chat-info__selected-topic').textContent = topic.getName();

    const chatHistory = getElementByClass('chat-history') as HTMLElement;
    getElementByClass('chat').classList.add(LCW + 'chat--' + type);

    function linkify(plainText: string): string{
        let replacedText;
        let replacePattern1;
        let replacePattern2;
        let replacePattern3;

        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = plainText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

        return replacedText;
       }

    const callbacks = {
        onAttachmentAdded: (filename: string, url: string, size: number, senderId: number, senderDisplayName: string) => {
            const sizeKb = Math.floor(size / 1024);
            const msgEl = document.createElement('div');
            msgEl.classList.add(LCW + 'chat-history__attachment');
            msgEl.innerHTML = `<i  aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5C8.12 15 7 13.88 7 12.5S8.12 10 9.5 10H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z"/></svg>
            </i>
            <a href="${url}?participantId=${publisherId}" class="${LCW}chat-history__attachment-download" target="_blank">
                <span class="${LCW}chat-history__attachment-filename">${filename}</span>
                <span class="${LCW}chat-history__attachment-size">${sizeKb} kB</span>
            </a>`;
            chatHistory.appendChild(msgEl);
            newMessageNotification();
            scrollChatHistory(chatHistory);
        },
        onLocalStream: (stream: MediaStream) => {
            localStream = stream;

            const localVideo: HTMLVideoElement = getElementByClass('videos__video--local');
            atachStream(stream, localVideo);
            
            localVideo.muted = true; // hack - set again
            const videoTracks = stream.getVideoTracks();
            if (videoTracks === null || videoTracks === undefined || videoTracks.length === 0) {
                localVideo.classList.add(LCW + 'videos__video--no-camera');
            }
            getElementByClass('container').classList.add(LCW + 'chat-videos');

            const isAudioEnabled = !getElementByClass('videos').classList.contains(
                LCW + 'videos--audio-disabled',
            );
            const isVideoEnabled = !getElementByClass('videos').classList.contains(
                LCW + 'videos--video-disabled',
            );
        
            // tslint:disable-next-line:no-console
            console.log('onLocalStream: isVideoEnabled, isVideoEnabled', isAudioEnabled, isVideoEnabled);
            restartVideo(isAudioEnabled, isVideoEnabled);
        },
        onMessageReceived: (message: string, senderId: number, senderDisplayName: string) => {
            const msgEl = document.createElement('div');
            msgEl.classList.add(LCW + 'chat-history__message');

            message = linkify(message);

            msgEl.innerHTML = message;
            chatHistory.appendChild(msgEl);
            const timeEl = document.createElement('div');
            timeEl.classList.add(LCW + 'chat-history__message-time');
            const currDate = new Date();
            timeEl.textContent = currDate.getHours() + ":" + currDate.getMinutes();
            chatHistory.appendChild(timeEl);
            if (senderId === publisherId) {
                msgEl.classList.add(LCW + 'chat-history__message--mine');
                timeEl.classList.add(LCW + 'chat-history__message-time--mine')
            } else {
                newMessageNotification();
            }
            scrollChatHistory(chatHistory);
        },
        onParticipantsChanged: (participants: Participant[]) => {
            const el = getElementByClass('chat-info__status');
            // FIXME parseInt !!!
            const otherParticipants = participants.filter(p => p.getId() !== publisherId);

            if (otherParticipants.length > 0) {
                el.textContent = otherParticipants.map(p => p.getName()).join(', ');
                getElementByClass('chat').classList.remove(LCW + 'chat--waiting');
                converstionStarted = true;
                (currentRoom as AVRoom).configure(true, true);
            }else if(converstionStarted) {
                close('agent_disconnected');
            }
        },
        onRemoteStream: (stream: MediaStream, id: number) => {
            // FIXME check configuration why empty video is sent (always for "clear" camera permissions in chrome)
            if(stream.getVideoTracks().length == 0) {
                console.warn('empty VideoTracks for', stream);
                return;
            }

            const remoteVideo: HTMLVideoElement = getElementByClass('videos__video--remote');
            atachStream(stream, remoteVideo);
        },
        onWebRtcError: (error: Error) => {
            if (error.name === "NotAllowedError") {
                alert(polyglot.t('not_allowed_error'));
                endScreenSharing();
            }
        }
    } as IRoomCallbacks;

    chatRoomClient
        .init({
            // onDestroyed
        })
        .then(() => {
            chatRoomClient.createRoom(topic.getId(), secret).then((roomId: number) => {
                currentRoomId = roomId;
                const input: HTMLInputElement = getElementByClass('chat-new-message__input');
                let join: Promise<Room>;
                if (type === ChatRoomType.Text) {
                    join = chatRoomClient.joinToTextChat(nickname, roomId, callbacks);
                } else {
                    join = chatRoomClient.joinAndPublish(nickname, roomId, type, callbacks);
                }
                join.then((room: Room) => {
                    currentRoom = room;
                    publisherId = room.getPublisherId()!;
                    if (type === ChatRoomType.Text) {
                        rootElement.classList.add(LCW_ROOTCLASS + '--chat-text');
                    }
                });
            });
        });
}

function atachStream(stream: MediaStream, remoteVideo: HTMLVideoElement) {
    if (adapter.browserDetails.browser === 'chrome') {
        const chromever = adapter.browserDetails.version;
        if (chromever >= 52) {
            remoteVideo.srcObject = stream;
        } else if (typeof remoteVideo.src !== 'undefined') {
            remoteVideo.src = URL.createObjectURL(stream);
        } else {
            throw new Error('Error attaching stream to element');
        }
    } else {
        remoteVideo.srcObject = stream;
    }
}

function newMessageNotification() {
    const textChatButton: HTMLButtonElement = getElementByClass('chat-info__button--message');
    textChatButton.style.backgroundColor = 'orange';
}

function reset() {
    publisherId = 0;
    currentRoomId = 0;

    const nicknameInput: HTMLInputElement = getElementByClass('welcome__input--nickname');
    nicknameInput.value = "";

    const topicSelect: HTMLSelectElement = getElementByClass('welcome__input--topic');
    topicSelect.selectedIndex = 0;

    const msgInput: HTMLInputElement = getElementByClass('chat-new-message__input');
    msgInput.value = "";

    const chatHistory = getElementByClass('chat-history');
    chatHistory.childNodes.forEach(node => node.remove());

    const remoteVideo: HTMLVideoElement = getElementByClass('videos__video--remote');
    remoteVideo.pause();
    remoteVideo.currentTime = 0;
    remoteVideo.src = '';
    remoteVideo.load();

    rootElement.classList.remove(LCW_ROOTCLASS + '--chat-text');
    getElementByClass('chat').classList.remove(LCW + 'chat--video');
    getElementByClass('chat').classList.remove(LCW + 'chat--audio');
    getElementByClass('chat').classList.remove(LCW + 'chat--text');
    getElementByClass('videos__video--local').classList.remove(LCW + 'videos__video--no-camera');
    getElementByClass('videos__video--remote').classList.remove(LCW + 'videos__video--no-camera');
    getElementByClass('videos').classList.remove(LCW + 'videos--screensharing-active');
    getElementByClass('videos').classList.remove(LCW + 'videos--audio-disabled');
    getElementByClass('videos').classList.remove(LCW + 'videos--video-disabled');
    getElementByClass('chat').classList.add(LCW + 'chat--waiting');
    getElementByClass('chat-info__status').textContent = '';
}

function createTopicOptions(topics: Topic[]) {
    topicList = topics;
    const topicInput = getElementByClass('welcome__input--topic') as HTMLSelectElement;
    if (topicList.length > 1){
        for (const topic of topics) {
            const option = document.createElement('option');
            option.value = topic.getId();
            option.text = topic.getName();
            topicInput.add(option);
        }
    }else{

        const option = document.createElement('option');
        option.value = topicList[0].getId();
        option.text = topicList[0].getName();
        option.selected = true;
        topicInput.add(option);
        topicInput.disabled=true;
        topicInput.classList.add('single')
        for (const nicknameKey of rootElement.getElementsByClassName(LCW + 'welcome__label--topic')) {
            (nicknameKey as HTMLLabelElement).remove();
        }
        for (const topicKey of rootElement.getElementsByClassName(LCW + 'welcome__input--topic')) {
            (topicKey as HTMLElement).classList.add('hidden');
        }
    }
}

function getElementByClass<T extends Element>(classSuffix: string): T {
    return rootElement.getElementsByClassName(LCW + classSuffix)[0] as T;
}

function isWebrtcSupported(): boolean {
    return (window as any).RTCPeerConnection !== undefined && (window as any).RTCPeerConnection !== null &&
		navigator.getUserMedia !== undefined && navigator.getUserMedia !== null;
}

function isFullScreen() {
    return FULL_SCREEN_PROJECTS_ID.some(el => el === (window as any).SIGNCONNECT_PROJECT);
}
